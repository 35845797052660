import React, { useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getFormatedDateAndMonth } from "../../utils/helpers";
import { useEffect } from "react";
import moment from "moment";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const timestamp = payload[0].payload.formated_changed_at;

    // Customize the tooltip content here
    const tooltipContent = (
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p>{`Changed At: ${getFormatedDateAndMonth(timestamp)}`}</p>
        {payload?.map((entry, index) => (
          <span key={`tooltip-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value}`}
          </span>
        ))}
      </div>
    );

    return tooltipContent;
  }

  return null;
};

const ChartData = ({ updateValues, keyResultData, count }) => {
  const [updatedValuesMerged, setUpdatedValuesMerged] = useState();
  useEffect(() => {
    if (updateValues && keyResultData) {
      let merged = updateValues
        ?.map((updatedValue, index) => {
          return {
            formated_changed_at: moment(updatedValue?.changed_at)?.valueOf(),
            ...updatedValue,
          };
        })
        ?.reverse();

      setUpdatedValuesMerged([
        {
          formated_changed_at: moment(keyResultData?.deadline).valueOf(),
          target_number: keyResultData?.target_number,
          new_number: keyResultData?.initial_number,
        },
        ...merged,
        {
          formated_changed_at: moment(keyResultData?.date_created).valueOf(),
          target_number: keyResultData?.target_number,
        },
      ]);
    }
  }, [updateValues, keyResultData]);

  return (
    <div>
      {updateValues && updatedValuesMerged ? (
        <LineChart width={450} height={300} data={updatedValuesMerged}>
          <Line
            type="monotone"
            dataKey="new_number"
            name={keyResultData?.key_result_type}
            stroke="#F44236"
            strokeWidth={3}
          />

          <CartesianGrid stroke="#ccc" />
          <XAxis
            dataKey="formated_changed_at"
            scale="time"
            domain={[
              updatedValuesMerged[0]?.formated_changed_at,
              updatedValuesMerged[updatedValuesMerged.length - 1]
                ?.formated_changed_at,
            ]}
            type="number"
            tickFormatter={getFormatedDateAndMonth}
          />
          <ReferenceLine
            label="Target"
            stroke="green"
            strokeDasharray="3 3"
            segment={[
              {
                x: updatedValuesMerged[0]?.formated_changed_at,
                y: updatedValuesMerged[0]?.new_number,
              },
              {
                x: updatedValuesMerged[updatedValuesMerged.length - 1]
                  ?.formated_changed_at,
                y: updatedValuesMerged[updatedValuesMerged.length - 1]
                  ?.target_number,
              },
            ]}
          />
          <YAxis
            domain={
              keyResultData?.key_result_type === "Should increase to"
                ? ["dataMin", keyResultData?.target_number]
                : [keyResultData?.target_number, "dataMax"]
            }
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </LineChart>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChartData;
