import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../styles/Register.css";
import { GoogleLogin } from "@react-oauth/google";
import { baseURL, siteKey } from "../utils/config";
import { Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";

import {
  logout,
  updateEmail,
  updateEmailVerification,
  updateUserSignup,
} from "../stores/slices/userAuthSlice";
import { useDispatch } from "react-redux";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Checkbox, CheckboxGroup, FormControl } from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

export default function Register() {
  const [formData, setformData] = useState({
    user_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    image: "",
    country: "",
  });
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [countries, setCountries] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = React.createRef();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    dispatch(logout());
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countryOptions = response.data.map((country) => ({
          code: country?.name?.common,
          name: country?.name?.common,
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const onCaptchaChange = (value) => {
    console.log(value);
    setCaptchaValue(value);
  };

  const handleAuthSignup = async (credential, provider, name) => {
    let signupData = {
      credential: credential,
      provider: provider,
    };

    setLoading(true);

    try {
      const result = await fetch(baseURL + "/signin/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(signupData),
      });
      if (result.status === 200) {
        const data = await result.json();
        dispatch(updateUserSignup(data));
        dispatch(updateEmailVerification(true));
        Cookies.set("access_token", data?.access_token);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "sign_up",
          message: "Sign Up Successful",
          user_name: result?.data?.username,
          user_email: result?.data?.email,
          event_ts: new Date(Date.now()).toLocaleString(),
        });
        navigate("/home");
      } else {
        toast.warn("Email already exists", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.warn("Email already exists", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  function changeHandeler(event) {
    const { name, value } = event.target;
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const fileChangeHandler = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profile_image", file);
      try {
        setImageLoading(true);
        const result = await axios.post(baseURL + "/profile-photo/", formData);
        if (result.status === 201) {
          setImageLoading(false);
          setformData({
            ...formData,
            image: result.data.image_url,
          });
        }
      } catch (error) {
        setImageLoading(false);
        // Handle errors
      }
    }
  };

  const saveHandler = async (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/;

    if (!emailRegex.test(formData.email)) {
      toast.warn("Please enter a valid email address", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!passwordRegex.test(formData.password)) {
      toast.warn(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character .",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return;
    }

    if (formData?.password !== formData?.confirmPassword) {
      toast.warn("Passwords don't match.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (formData?.password === formData?.email) {
      toast.warn("Email and password should not be same .", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!formData?.companyName) {
      toast.warn("Please enter your company name", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!captchaValue) {
      toast.warn(
        "Please complete the ReCAPTCHA to proceed with form submission.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      return;
    }

    setLoading(true);
    const username = formData.last_name
      ? `${formData.first_name} ${formData.last_name}`
      : formData.first_name;
    try {
      const result = await axios.post(baseURL + "/signin/", {
        username: username.trim(),
        email: formData.email,
        password: formData.password,
        profile_image: formData.image,
        company_name: formData.companyName,
        country: formData?.country,
      });
      setLoading(false);
      dispatch(updateEmail(formData.email));
      if (result.status === 200) {
        navigate("/verify-email");
      } else {
        const data = await result.json();
        setLoading(false);
        toast.error(data?.email || data?.password || "Account already exists");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Account already exists");
    }
  };

  return (
    <div className="login_container overflow-hidden ">
      <div
        className="wrapper_container flex "
        style={{
          width: "940px",
          height: "580px",
        }}
      >
        <div className="flex justify-center items-center px-2 w-3/5">
          <div className="flex flex-col justify-center pl-6  w-full">
            <form
              onSubmit={saveHandler}
              className="flex flex-col w-full justify-center items-center "
            >
              <div
                className="w-full"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "8px",
                }}
              >
                {/* Left Text */}
                <div
                  className="font-poppins"
                  style={{
                    fontSize: "22px",
                    fontWeight: "semibold",
                    color: "#347E96",
                  }}
                >
                  Let’s get started
                </div>

                {/* Right Image Upload Section */}
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label htmlFor="image" style={{ cursor: "pointer" }}>
                    <div
                      style={{
                        width: "65px",
                        height: "65px",
                        borderRadius: "50%",
                        position: "relative",
                        border: "1px solid #1e90ff",
                        backgroundColor: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {formData.image ? (
                        <img
                          src={formData.image}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          onLoad={() => setImageLoading(false)}
                          onError={() => setImageLoading(false)}
                        />
                      ) : (
                        <svg
                          width="30"
                          height="32"
                          viewBox="0 0 30 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.33333 7.06378C8.33333 5.19035 9.03571 3.39365 10.286 2.06893C11.5362 0.744217 13.2319 0 15 0C16.7681 0 18.4638 0.744217 19.714 2.06893C20.9643 3.39365 21.6667 5.19035 21.6667 7.06378C21.6667 8.93721 20.9643 10.7339 19.714 12.0586C18.4638 13.3833 16.7681 14.1276 15 14.1276C13.2319 14.1276 11.5362 13.3833 10.286 12.0586C9.03571 10.7339 8.33333 8.93721 8.33333 7.06378ZM8.33333 17.6594C6.1232 17.6594 4.00358 18.5897 2.44078 20.2456C0.877974 21.9015 0 24.1474 0 26.4892C0 27.8942 0.526784 29.2418 1.46447 30.2353C2.40215 31.2288 3.67392 31.787 5 31.787H25C26.3261 31.787 27.5979 31.2288 28.5355 30.2353C29.4732 29.2418 30 27.8942 30 26.4892C30 24.1474 29.122 21.9015 27.5592 20.2456C25.9964 18.5897 23.8768 17.6594 21.6667 17.6594H8.33333Z"
                            fill="#AFB0B6"
                          />
                        </svg>
                      )}

                      <div className="absolute bottom-0 right-0 z-5">
                        <div
                          className="bg-white rounded-full border flex justify-center items-center"
                          style={{
                            borderColor: "#63ACC5",
                            width: "21px",
                            height: "21px",
                          }}
                        >
                          <label htmlFor="image-upload">
                            {imageLoading ? (
                              <Spin style={{ fontSize: "8px" }} />
                            ) : (
                              <MdOutlineModeEditOutline
                                style={{ fontSize: "10px" }}
                              />
                            )}
                          </label>
                          <input
                            type="file"
                            id="image-upload"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={fileChangeHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div style={{ display: "flex", gap: "10px" }} className="w-full">
                {/* First Name */}
                <div style={{ flex: 1 }}>
                  <label
                    className="font-medium text-xs"
                    style={{ color: "#347E96" }}
                  >
                    First Name
                  </label>
                  <div style={{ position: "relative" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: "1",
                      }}
                    >
                      <path
                        d="M9.33329 14V11.95L13.0166 8.28333C13.1166 8.18333 13.2277 8.11111 13.35 8.06666C13.4722 8.02222 13.5944 8 13.7166 8C13.85 8 13.9777 8.025 14.1 8.075C14.2222 8.125 14.3333 8.2 14.4333 8.3L15.05 8.91666C15.1388 9.01666 15.2083 9.12777 15.2583 9.25C15.3083 9.37222 15.3333 9.49444 15.3333 9.61666C15.3333 9.73889 15.3111 9.86389 15.2666 9.99166C15.2222 10.1194 15.15 10.2333 15.05 10.3333L11.3833 14H9.33329ZM2.66663 13.3333V11.4667C2.66663 11.0889 2.76385 10.7417 2.95829 10.425C3.15274 10.1083 3.41107 9.86666 3.73329 9.7C4.42218 9.35555 5.12218 9.09722 5.83329 8.925C6.5444 8.75277 7.26663 8.66666 7.99996 8.66666C8.41107 8.66666 8.81663 8.69166 9.21663 8.74166C9.61663 8.79166 10.0166 8.87222 10.4166 8.98333L7.99996 11.4V13.3333H2.66663ZM13.7166 10.2667L14.3333 9.61666L13.7166 9L13.0833 9.63333L13.7166 10.2667ZM7.99996 8C7.26663 8 6.63885 7.73889 6.11663 7.21666C5.5944 6.69444 5.33329 6.06666 5.33329 5.33333C5.33329 4.6 5.5944 3.97222 6.11663 3.45C6.63885 2.92778 7.26663 2.66666 7.99996 2.66666C8.73329 2.66666 9.36107 2.92778 9.88329 3.45C10.4055 3.97222 10.6666 4.6 10.6666 5.33333C10.6666 6.06666 10.4055 6.69444 9.88329 7.21666C9.36107 7.73889 8.73329 8 7.99996 8Z"
                        fill="#AFB0B6"
                      />
                    </svg>
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      onChange={changeHandeler}
                      value={formData.first_name}
                      placeholder="Enter your first name"
                      maxLength={25}
                      required
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#F8F9F9",
                        border: "none",
                        width: "100%",
                        fontSize: "12px",
                      }}
                    />
                  </div>
                </div>

                {/* Last Name */}
                <div style={{ flex: 1 }}>
                  <label
                    className="font-medium text-xs"
                    style={{ color: "#347E96" }}
                  >
                    Last Name
                  </label>
                  <div style={{ position: "relative" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: "1",
                      }}
                    >
                      <path
                        d="M9.33329 14V11.95L13.0166 8.28333C13.1166 8.18333 13.2277 8.11111 13.35 8.06666C13.4722 8.02222 13.5944 8 13.7166 8C13.85 8 13.9777 8.025 14.1 8.075C14.2222 8.125 14.3333 8.2 14.4333 8.3L15.05 8.91666C15.1388 9.01666 15.2083 9.12777 15.2583 9.25C15.3083 9.37222 15.3333 9.49444 15.3333 9.61666C15.3333 9.73889 15.3111 9.86389 15.2666 9.99166C15.2222 10.1194 15.15 10.2333 15.05 10.3333L11.3833 14H9.33329ZM2.66663 13.3333V11.4667C2.66663 11.0889 2.76385 10.7417 2.95829 10.425C3.15274 10.1083 3.41107 9.86666 3.73329 9.7C4.42218 9.35555 5.12218 9.09722 5.83329 8.925C6.5444 8.75277 7.26663 8.66666 7.99996 8.66666C8.41107 8.66666 8.81663 8.69166 9.21663 8.74166C9.61663 8.79166 10.0166 8.87222 10.4166 8.98333L7.99996 11.4V13.3333H2.66663ZM13.7166 10.2667L14.3333 9.61666L13.7166 9L13.0833 9.63333L13.7166 10.2667ZM7.99996 8C7.26663 8 6.63885 7.73889 6.11663 7.21666C5.5944 6.69444 5.33329 6.06666 5.33329 5.33333C5.33329 4.6 5.5944 3.97222 6.11663 3.45C6.63885 2.92778 7.26663 2.66666 7.99996 2.66666C8.73329 2.66666 9.36107 2.92778 9.88329 3.45C10.4055 3.97222 10.6666 4.6 10.6666 5.33333C10.6666 6.06666 10.4055 6.69444 9.88329 7.21666C9.36107 7.73889 8.73329 8 7.99996 8Z"
                        fill="#AFB0B6"
                      />
                    </svg>
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      onChange={changeHandeler}
                      value={formData.last_name}
                      placeholder="Enter your last name"
                      maxLength={25}
                      required
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#F8F9F9",
                        border: "none",
                        width: "100%",
                        fontSize: "12px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "4PX" }} className="w-full">
                <label
                  className="font-medium text-xs"
                  style={{ color: "#347E96" }}
                >
                  Email address
                </label>
                <div style={{ position: "relative", marginTop: "3px" }}>
                  <svg
                    width="13"
                    height="11"
                    viewBox="0 0 13 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "calc(50%)",
                      transform: "translateY(-25%)",
                      zIndex: "1",
                    }}
                  >
                    <path
                      d="M1.5 10.5C1.15625 10.5 0.861979 10.3776 0.617188 10.1328C0.372396 9.88802 0.25 9.59375 0.25 9.25V1.75C0.25 1.40625 0.372396 1.11198 0.617188 0.867188C0.861979 0.622396 1.15625 0.5 1.5 0.5H11.5C11.8438 0.5 12.138 0.622396 12.3828 0.867188C12.6276 1.11198 12.75 1.40625 12.75 1.75V9.25C12.75 9.59375 12.6276 9.88802 12.3828 10.1328C12.138 10.3776 11.8438 10.5 11.5 10.5H1.5ZM6.5 6.125L11.5 3V1.75L6.5 4.875L1.5 1.75V3L6.5 6.125Z"
                      fill="#AFB0B6"
                    />
                  </svg>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    onChange={changeHandeler}
                    value={formData.email}
                    placeholder="Enter your email"
                    required
                    style={{
                      paddingLeft: "30px",
                      paddingRight: "10px", // Add padding to the right for better spacing
                      borderRadius: "5px",
                      backgroundColor: "#F8F9F9",
                      border: "none",
                      width: "100%",
                      fontSize: "12px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{ display: "flex", gap: "10px", marginTop: "4PX" }}
                className="w-full"
              >
                <div style={{ flex: 1 }}>
                  <label
                    className="font-medium text-xs"
                    style={{ color: "#347E96" }}
                  >
                    Password
                  </label>
                  <div style={{ position: "relative", marginTop: "3px" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      onChange={changeHandeler}
                      value={formData.password}
                      placeholder="Enter your password"
                      required
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "10px", // Add padding to the right for better spacing
                        borderRadius: "5px",
                        backgroundColor: "#F8F9F9",
                        border: "none",
                        width: "100%",
                        fontSize: "12px",
                      }}
                    />
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "calc(50%)",
                        transform: "translateY(-25%)",
                        cursor: "pointer",
                      }}
                    >
                      <path
                        d="M1.99996 14.6667C1.63329 14.6667 1.3194 14.5361 1.05829 14.275C0.797182 14.0139 0.666626 13.7 0.666626 13.3333V6.66666C0.666626 6.29999 0.797182 5.9861 1.05829 5.72499C1.3194 5.46388 1.63329 5.33332 1.99996 5.33332H2.66663V3.99999C2.66663 3.07777 2.99163 2.29166 3.64163 1.64166C4.29163 0.991657 5.07774 0.666656 5.99996 0.666656C6.92218 0.666656 7.70829 0.991657 8.35829 1.64166C9.00829 2.29166 9.33329 3.07777 9.33329 3.99999V5.33332H9.99996C10.3666 5.33332 10.6805 5.46388 10.9416 5.72499C11.2027 5.9861 11.3333 6.29999 11.3333 6.66666V13.3333C11.3333 13.7 11.2027 14.0139 10.9416 14.275C10.6805 14.5361 10.3666 14.6667 9.99996 14.6667H1.99996ZM5.99996 11.3333C6.36663 11.3333 6.68052 11.2028 6.94163 10.9417C7.20274 10.6805 7.33329 10.3667 7.33329 9.99999C7.33329 9.63332 7.20274 9.31944 6.94163 9.05832C6.68052 8.79721 6.36663 8.66666 5.99996 8.66666C5.63329 8.66666 5.3194 8.79721 5.05829 9.05832C4.79718 9.31944 4.66663 9.63332 4.66663 9.99999C4.66663 10.3667 4.79718 10.6805 5.05829 10.9417C5.3194 11.2028 5.63329 11.3333 5.99996 11.3333ZM3.99996 5.33332H7.99996V3.99999C7.99996 3.44443 7.80552 2.97221 7.41663 2.58332C7.02774 2.19443 6.55552 1.99999 5.99996 1.99999C5.4444 1.99999 4.97218 2.19443 4.58329 2.58332C4.1944 2.97221 3.99996 3.44443 3.99996 3.99999V5.33332Z"
                        fill="#AFB0B6"
                      />
                    </svg>

                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "calc(50%)",
                        transform: "translateY(-25%)",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        // Hide icon
                        <path
                          d="M6 8C6.625 8 7.15625 7.78125 7.59375 7.34375C8.03125 6.90625 8.25 6.375 8.25 5.75C8.25 5.125 8.03125 4.59375 7.59375 4.15625C7.15625 3.71875 6.625 3.5 6 3.5C5.375 3.5 4.84375 3.71875 4.40625 4.15625C3.96875 4.59375 3.75 5.125 3.75 5.75C3.75 6.375 3.96875 6.90625 4.40625 7.34375C4.84375 7.78125 5.375 8 6 8ZM6 7.1C5.625 7.1 5.30625 6.96875 5.04375 6.70625C4.78125 6.44375 4.65 6.125 4.65 5.75C4.65 5.375 4.78125 5.05625 5.04375 4.79375C5.30625 4.53125 5.625 4.4 6 4.4C6.375 4.4 6.69375 4.53125 6.95625 4.79375C7.21875 5.05625 7.35 5.375 7.35 5.75C7.35 6.125 7.21875 6.44375 6.95625 6.70625C6.69375 6.96875 6.375 7.1 6 7.1ZM6 9.5C4.78333 9.5 3.675 9.16042 2.675 8.48125C1.675 7.80208 0.95 6.89167 0.5 5.75C0.95 4.60833 1.675 3.69792 2.675 3.01875C3.675 2.33958 4.78333 2 6 2C7.21667 2 8.325 2.33958 9.325 3.01875C10.325 3.69792 11.05 4.60833 11.5 5.75C11.05 6.89167 10.325 7.80208 9.325 8.48125C8.325 9.16042 7.21667 9.5 6 9.5Z"
                          fill="#AFB0B6"
                        />
                      ) : (
                        <path
                          d="M9.9 11.3L7.8 9.22499C7.50833 9.31666 7.21458 9.38541 6.91875 9.43124C6.62292 9.47708 6.31667 9.49999 6 9.49999C4.74167 9.49999 3.62083 9.15208 2.6375 8.45624C1.65417 7.76041 0.941667 6.85833 0.5 5.74999C0.675 5.30833 0.895833 4.89791 1.1625 4.51874C1.42917 4.13958 1.73333 3.79999 2.075 3.49999L0.7 2.09999L1.4 1.39999L10.6 10.6L9.9 11.3ZM6 7.99999C6.09167 7.99999 6.17708 7.99583 6.25625 7.98749C6.33542 7.97916 6.42083 7.96249 6.5125 7.93749L3.8125 5.23749C3.7875 5.32916 3.77083 5.41458 3.7625 5.49374C3.75417 5.57291 3.75 5.65833 3.75 5.74999C3.75 6.37499 3.96875 6.90624 4.40625 7.34374C4.84375 7.78124 5.375 7.99999 6 7.99999ZM9.65 8.22499L8.0625 6.64999C8.12083 6.50833 8.16667 6.36458 8.2 6.21874C8.23333 6.07291 8.25 5.91666 8.25 5.74999C8.25 5.12499 8.03125 4.59374 7.59375 4.15624C7.15625 3.71874 6.625 3.49999 6 3.49999C5.83333 3.49999 5.67708 3.51666 5.53125 3.54999C5.38542 3.58333 5.24167 3.63333 5.1 3.69999L3.825 2.42499C4.16667 2.28333 4.51667 2.17708 4.875 2.10624C5.23333 2.03541 5.60833 1.99999 6 1.99999C7.25833 1.99999 8.37917 2.34791 9.3625 3.04374C10.3458 3.73958 11.0583 4.64166 11.5 5.74999C11.3083 6.24166 11.0563 6.69791 10.7438 7.11874C10.4313 7.53958 10.0667 7.90833 9.65 8.22499ZM7.3375 5.92499L5.8375 4.42499C6.07083 4.38333 6.28542 4.40208 6.48125 4.48124C6.67708 4.56041 6.84583 4.67499 6.9875 4.82499C7.12917 4.97499 7.23125 5.14791 7.29375 5.34374C7.35625 5.53958 7.37083 5.73333 7.3375 5.92499Z"
                          fill="#AFB0B6"
                        />
                      )}
                    </svg>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <label
                    className="font-medium text-xs"
                    style={{ color: "#347E96" }}
                  >
                    Confirm Password
                  </label>
                  <div style={{ position: "relative", marginTop: "3px" }}>
                    <input
                      type={showPassword2 ? "text" : "password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      onChange={changeHandeler}
                      value={formData.confirmPassword}
                      placeholder="Confirm Password"
                      required
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "10px", // Add padding to the right for better spacing
                        borderRadius: "5px",
                        backgroundColor: "#F8F9F9",
                        border: "none",
                        width: "100%",
                        fontSize: "12px",
                      }}
                    />
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "calc(50%)",
                        transform: "translateY(-25%)",
                        cursor: "pointer",
                      }}
                    >
                      <path
                        d="M1.99996 14.6667C1.63329 14.6667 1.3194 14.5361 1.05829 14.275C0.797182 14.0139 0.666626 13.7 0.666626 13.3333V6.66666C0.666626 6.29999 0.797182 5.9861 1.05829 5.72499C1.3194 5.46388 1.63329 5.33332 1.99996 5.33332H2.66663V3.99999C2.66663 3.07777 2.99163 2.29166 3.64163 1.64166C4.29163 0.991657 5.07774 0.666656 5.99996 0.666656C6.92218 0.666656 7.70829 0.991657 8.35829 1.64166C9.00829 2.29166 9.33329 3.07777 9.33329 3.99999V5.33332H9.99996C10.3666 5.33332 10.6805 5.46388 10.9416 5.72499C11.2027 5.9861 11.3333 6.29999 11.3333 6.66666V13.3333C11.3333 13.7 11.2027 14.0139 10.9416 14.275C10.6805 14.5361 10.3666 14.6667 9.99996 14.6667H1.99996ZM5.99996 11.3333C6.36663 11.3333 6.68052 11.2028 6.94163 10.9417C7.20274 10.6805 7.33329 10.3667 7.33329 9.99999C7.33329 9.63332 7.20274 9.31944 6.94163 9.05832C6.68052 8.79721 6.36663 8.66666 5.99996 8.66666C5.63329 8.66666 5.3194 8.79721 5.05829 9.05832C4.79718 9.31944 4.66663 9.63332 4.66663 9.99999C4.66663 10.3667 4.79718 10.6805 5.05829 10.9417C5.3194 11.2028 5.63329 11.3333 5.99996 11.3333ZM3.99996 5.33332H7.99996V3.99999C7.99996 3.44443 7.80552 2.97221 7.41663 2.58332C7.02774 2.19443 6.55552 1.99999 5.99996 1.99999C5.4444 1.99999 4.97218 2.19443 4.58329 2.58332C4.1944 2.97221 3.99996 3.44443 3.99996 3.99999V5.33332Z"
                        fill="#AFB0B6"
                      />
                    </svg>

                    {/* Visibility toggle icon */}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "calc(50%)",
                        transform: "translateY(-25%)",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword2(!showPassword2)}
                    >
                      {showPassword2 ? (
                        // Hide icon
                        <path
                          d="M6 8C6.625 8 7.15625 7.78125 7.59375 7.34375C8.03125 6.90625 8.25 6.375 8.25 5.75C8.25 5.125 8.03125 4.59375 7.59375 4.15625C7.15625 3.71875 6.625 3.5 6 3.5C5.375 3.5 4.84375 3.71875 4.40625 4.15625C3.96875 4.59375 3.75 5.125 3.75 5.75C3.75 6.375 3.96875 6.90625 4.40625 7.34375C4.84375 7.78125 5.375 8 6 8ZM6 7.1C5.625 7.1 5.30625 6.96875 5.04375 6.70625C4.78125 6.44375 4.65 6.125 4.65 5.75C4.65 5.375 4.78125 5.05625 5.04375 4.79375C5.30625 4.53125 5.625 4.4 6 4.4C6.375 4.4 6.69375 4.53125 6.95625 4.79375C7.21875 5.05625 7.35 5.375 7.35 5.75C7.35 6.125 7.21875 6.44375 6.95625 6.70625C6.69375 6.96875 6.375 7.1 6 7.1ZM6 9.5C4.78333 9.5 3.675 9.16042 2.675 8.48125C1.675 7.80208 0.95 6.89167 0.5 5.75C0.95 4.60833 1.675 3.69792 2.675 3.01875C3.675 2.33958 4.78333 2 6 2C7.21667 2 8.325 2.33958 9.325 3.01875C10.325 3.69792 11.05 4.60833 11.5 5.75C11.05 6.89167 10.325 7.80208 9.325 8.48125C8.325 9.16042 7.21667 9.5 6 9.5Z"
                          fill="#AFB0B6"
                        />
                      ) : (
                        <path
                          d="M9.9 11.3L7.8 9.22499C7.50833 9.31666 7.21458 9.38541 6.91875 9.43124C6.62292 9.47708 6.31667 9.49999 6 9.49999C4.74167 9.49999 3.62083 9.15208 2.6375 8.45624C1.65417 7.76041 0.941667 6.85833 0.5 5.74999C0.675 5.30833 0.895833 4.89791 1.1625 4.51874C1.42917 4.13958 1.73333 3.79999 2.075 3.49999L0.7 2.09999L1.4 1.39999L10.6 10.6L9.9 11.3ZM6 7.99999C6.09167 7.99999 6.17708 7.99583 6.25625 7.98749C6.33542 7.97916 6.42083 7.96249 6.5125 7.93749L3.8125 5.23749C3.7875 5.32916 3.77083 5.41458 3.7625 5.49374C3.75417 5.57291 3.75 5.65833 3.75 5.74999C3.75 6.37499 3.96875 6.90624 4.40625 7.34374C4.84375 7.78124 5.375 7.99999 6 7.99999ZM9.65 8.22499L8.0625 6.64999C8.12083 6.50833 8.16667 6.36458 8.2 6.21874C8.23333 6.07291 8.25 5.91666 8.25 5.74999C8.25 5.12499 8.03125 4.59374 7.59375 4.15624C7.15625 3.71874 6.625 3.49999 6 3.49999C5.83333 3.49999 5.67708 3.51666 5.53125 3.54999C5.38542 3.58333 5.24167 3.63333 5.1 3.69999L3.825 2.42499C4.16667 2.28333 4.51667 2.17708 4.875 2.10624C5.23333 2.03541 5.60833 1.99999 6 1.99999C7.25833 1.99999 8.37917 2.34791 9.3625 3.04374C10.3458 3.73958 11.0583 4.64166 11.5 5.74999C11.3083 6.24166 11.0563 6.69791 10.7438 7.11874C10.4313 7.53958 10.0667 7.90833 9.65 8.22499ZM7.3375 5.92499L5.8375 4.42499C6.07083 4.38333 6.28542 4.40208 6.48125 4.48124C6.67708 4.56041 6.84583 4.67499 6.9875 4.82499C7.12917 4.97499 7.23125 5.14791 7.29375 5.34374C7.35625 5.53958 7.37083 5.73333 7.3375 5.92499Z"
                          fill="#AFB0B6"
                        />
                      )}
                    </svg>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "10px", marginTop: "4px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    className="font-medium text-xs"
                    style={{ color: "#347E96" }}
                  >
                    Country
                  </label>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      marginTop: "4px",
                    }}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: "1",
                      }}
                    >
                      <path
                        d="M0.34375 12.6562V2.90625H2.92188V0.328125H10.0938V5.48438H12.6719V12.6562H7.32812V9.54688H5.67188V12.6562H0.34375ZM1.8125 11.1875H3.45312V9.54688H1.8125V11.1875ZM1.8125 8.60938H3.45312V6.96875H1.8125V8.60938ZM1.8125 6.03125H3.45312V4.39062H1.8125V6.03125ZM4.39062 8.60938H6.03125V6.96875H4.39062V8.60938ZM4.39062 6.03125H6.03125V4.39062H4.39062V6.03125ZM4.39062 3.45312H6.03125V1.8125H4.39062V3.45312ZM6.96875 8.60938H8.60938V6.96875H6.96875V8.60938ZM6.96875 6.03125H8.60938V4.39062H6.96875V6.03125ZM6.96875 3.45312H8.60938V1.8125H6.96875V3.45312ZM9.54688 11.1875H11.1875V9.54688H9.54688V11.1875ZM9.54688 8.60938H11.1875V6.96875H9.54688V8.60938Z"
                        fill="#AFB0B6"
                      />
                    </svg>
                    <select
                      name="country"
                      id="country"
                      onChange={changeHandeler}
                      value={formData.country}
                      placeholder="Select you Country"
                      required
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#F8F9F9",
                        border: "none",
                        width: "100%",
                        height: "35px",
                        color: "#aaa",
                        fontSize: "12px",
                      }}
                    >
                      <option value="" disabled selected>
                        Select your country
                      </option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <label
                    className="font-medium text-xs"
                    style={{ color: "#347E96" }}
                  >
                    Company Name
                  </label>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      // marginTop: "2px",
                      // paddingTop: "4px",
                    }}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: "1",
                      }}
                    >
                      <path
                        d="M0.34375 12.6562V2.90625H2.92188V0.328125H10.0938V5.48438H12.6719V12.6562H7.32812V9.54688H5.67188V12.6562H0.34375ZM1.8125 11.1875H3.45312V9.54688H1.8125V11.1875ZM1.8125 8.60938H3.45312V6.96875H1.8125V8.60938ZM1.8125 6.03125H3.45312V4.39062H1.8125V6.03125ZM4.39062 8.60938H6.03125V6.96875H4.39062V8.60938ZM4.39062 6.03125H6.03125V4.39062H4.39062V6.03125ZM4.39062 3.45312H6.03125V1.8125H4.39062V3.45312ZM6.96875 8.60938H8.60938V6.96875H6.96875V8.60938ZM6.96875 6.03125H8.60938V4.39062H6.96875V6.03125ZM6.96875 3.45312H8.60938V1.8125H6.96875V3.45312ZM9.54688 11.1875H11.1875V9.54688H9.54688V11.1875ZM9.54688 8.60938H11.1875V6.96875H9.54688V8.60938Z"
                        fill="#AFB0B6"
                      />
                    </svg>
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      onChange={changeHandeler}
                      value={formData.companyName}
                      placeholder="Enter company name"
                      required
                      style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#F8F9F9",
                        border: "none",
                        width: "100%",
                        height: "35px",
                        fontSize: "12px", // Consistent height
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <ReCAPTCHA
                    sitekey={siteKey}
                    onChange={onCaptchaChange}
                    ref={recaptchaRef}
                  />
                </div>
                <div
                  className="text-xs flex justify-center items-center font-poppins font-light "
                  style={{ flex: 1, color: "#595F66B2", opacity: "70%" }}
                >
                  Please complete the ReCAPTCHA to proceed with form submission.
                </div>
              </div>

              <div className="flex justify-between w-full gap-x-4 items-center mt-3">
                <button
                  className="register_button font-semibold "
                  type="submit"
                  // disabled={!isChecked}
                >
                  {loading ? (
                    <img
                      src="/assets/images/WhiteLoader.svg"
                      className="w-8 h-8"
                    />
                  ) : (
                    "Register"
                  )}
                </button>
                <span className="flex justify-center text-xs items-center  w-1/4">
                  -OR-
                </span>
                <div className="w-full justify-end">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      handleAuthSignup(
                        credentialResponse?.credential,
                        "google"
                      );
                    }}
                    text="signup_with"
                    useOneTap
                  />
                </div>
              </div>
              <FormControl
                style={{
                  marginTop: "8px",
                  width: "100%",
                  fontSize: "14px",
                }}
              >
                <CheckboxGroup
                  defaultValue={["Label"]}
                  className="text-xs font-poppins font-light "
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span
                    className=" mr-2"
                    style={{ color: "#595F66B2", opacity: "70%" }}
                  >
                    By clicking Register button you are agreeing to the
                  </span>
                  <Link
                    to="/termsofservices"
                    className="text-[#347E96] underline"
                  >
                    Terms of Service
                  </Link>
                  <span style={{ color: "#595F66B2", opacity: "70%" }}>
                    {" "}
                    and{" "}
                  </span>
                  <Link
                    to="/privacypolicy"
                    className="text-[#347E96] underline"
                  >
                    Privacy Policy
                  </Link>
                </CheckboxGroup>
              </FormControl>
            </form>
          </div>
        </div>
        <div
          className="flex justify-center items-center transform translate-x-1/2"
          style={{
            zIndex: "999",
          }}
        >
          <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center">
            <img src="/assets/images/LogoImongu.png" className="w-10 h-10" />
          </div>
        </div>
        <div
          className="w-2/5 animate-slideRight"
          style={{
            backgroundImage: `url('/assets/images/loginImage.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundSize: "100% ",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            // marginTop: "20px",
          }}
        >
          <div className="w-full h-full  flex flex-col justify-between text-white">
            <div className="h-3/4 w-full flex flex-col text-center items-center justify-center">
              <div className=" text-4xl w-10/12 font-semibold">Hello!</div>
              <div className="w-10/12">
                Enter your details to start the journey with us.
              </div>
            </div>
            <div className="h-1/4 flex flex-col p-10 justify-end account_wrapper text-center">
              <span className="account">
                Already have an account?{" "}
                <Link to="/login" className="font-semibold">
                  Sign In
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
