import React, { useEffect, useState } from "react";
import { Progress } from "antd";
import { BiGlobe } from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  handleOpenGoalDrawer,
  handleOpenKeyresultDrawer,
  handleOpenOkrDrawer,
} from "../../../stores/slices/okrSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAvatarColor, getInitials } from "../../../utils/helpers";

const ExecutiveInsightsTable = ({ data, type }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  const handleOpenDrawerWithKey = (okr_id) => {
    if (user?.roleAccess?.Objective?.View) {
      dispatch(handleOpenOkrDrawer(okr_id));
    }
  };

  const handleOpenDrawerWithKey1 = (key_id) => {
    if(user?.roleAccess?.['Key-Results']?.View){
    dispatch(handleOpenKeyresultDrawer(key_id));
    }
  };

  const handleOpenDrawerWithKey2 = (goal_id) => {
    if (user?.roleAccess?.Goal?.View) {
      dispatch(handleOpenGoalDrawer(goal_id));
    }
  };

  return (
    <div className="w-full h-full p-2">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-basic">
          All{" "}
          {type === "Objective"
            ? "Objectives"
            : type === "Goal"
            ? "Goals"
            : "Key Results"}
        </h2>
      </div>
      <div className="grid grid-cols-3 w-full mt-5 bg-header text-white p-3">
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Title</h3>
        </div>
        <div className="flex items-center col-span-1 justify-start">
          <h3 className="text-lg font-medium">Owner(s)</h3>
        </div>
        <div className="flex items-center col-span-1 flex-start">
          <h3 className="text-lg font-medium">Progress</h3>
        </div>
      </div>
      {data?.map((item, i) => (
        <div
          className={`grid grid-cols-3 w-full py-4  border border-b`}
          key={i}
        >
          <div className="flex items-center justify-start ml-3 gap-x-2 col-span-1 w-full px-2 ">
            {type === "Objective" && item?.okr_id ? (
              <Link
                className="text-medium font-base text-basic hover:text-blue-600 cursor-pointer"
                onClick={() => handleOpenDrawerWithKey(item?.okr_id)}
              >
                {item?.title}
              </Link>
            ) : type === "Key Result" && item?.key_id ? (
              <Link
                className="text-medium font-base text-basic hover:text-blue-600 cursor-pointer"
                onClick={() => handleOpenDrawerWithKey1(item?.key_id)}
              >
                {item?.title}
              </Link>
            ) : (
              <span
                className="text-medium font-base hover:text-blue-600 text-basic cursor-pointer"
                onClick={() => handleOpenDrawerWithKey2(item?.goal_id)}
              >
                {item?.title}
              </span>
            )}
          </div>

          <div className="flex flex-col w-full mx-auto gap-x-2 col-span-1">
            <ul>
              {item?.owners?.map((owner, index) => (
                <li key={index} className="flex items-center  gap-x-2">
                  {owner?.type === "user" ? (
                    <div
                      className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                      style={{
                        backgroundColor: owner?.profile_image
                          ? "initial"
                          : getAvatarColor(owner?.username),
                      }}
                      size={18}
                    >
                      {owner?.profile_image ? (
                        <img
                          src={owner?.profile_image}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                            display: "block",
                          }}
                        />
                      ) : (
                        getInitials(owner.name)
                      )}
                    </div>
                  ) : (
                    <span className="p-1 bg-gray-600 rounded-full w-5 h-5 ">
                      <BiGlobe className="text-gray-300 text-xs" />
                    </span>
                  )}
                  {owner?.name}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex items-center flex-start gap-x-2 col-span-1">
            <Progress
              percent={item?.overall_gain}
              strokeColor={
                parseInt(item?.overall_gain) < 100 ? "#F77C33" : "#0AA959"
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
};
export default ExecutiveInsightsTable;
