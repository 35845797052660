import React, { useEffect } from "react";
import { BiCheck } from "react-icons/bi";
import { useSelector } from "react-redux";
import { getAvatarColor, getInitials } from "../../utils/helpers";

const SwitchAccount = ({ handleCompanyChange }) => {
  const user = useSelector((state) => state?.user);
  const currentUser = user?.currentUser;
  const currentCompany = user?.currentCompany;



  return (
    <form className="p-4 flex flex-col w-full">
      <h1 className="text-xl text-center font-semibold">Switch Companies</h1>
      <div className="flex flex-col w-full mt-4 gap-y-2">
        {currentUser?.employees?.map((employee, index) => (
          <div
            className={`flex items-center   w-full p-4 border-b rounded-md transition-all gap-x-2  ${
              currentCompany === index ? "shadow" : "hover:bg-slate-200"
            }  `}
            key={index}
            onClick={() => handleCompanyChange(index)}
          >
            {currentCompany === index ? (
              <BiCheck className="text-base text-green-700" />
            ) : (
              ""
            )}
            <div className="flex items-center gap-x-2 text-sm font-semibold cursor-pointer">
              <h4>Owner:</h4>
              <div className="flex items-center gap-x-2">
                <div
                  className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                  style={{
                    backgroundColor: employee?.profile_image
                      ? "initial"
                      : getAvatarColor(employee?.username),
                  }}
                  size={18}
                >
                  {employee?.profile_image ? (
                    <img
                      src={employee.profile_image}
                      alt="Profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                        display: "block",
                      }}
                    />
                  ) : (
                    getInitials(employee?.username)
                  )}
                </div>
                <span>{employee.username}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </form>
  );
};

export default SwitchAccount;
