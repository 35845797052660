import React from "react";
import { Select } from "antd";
import { BiGlobe } from "react-icons/bi";
import { MenuButton, MenuList, Menu, MenuItem } from "@chakra-ui/react";

const FilterEmployees = ({
  filterModal,
  handleCancel,
  formData,
  setformData,
  owners,
  removeField,
  addField,
  values,
  visibleFields,
  setVisibleFields,
}) => {



  return (
    <div
      className="text-black absolute bg-white z-50 top-0 right-0  rounded-md p-4 pl-12"
      // onClick={handleModalClick}
      style={{
        width: "600px",
        boxShadow: "0px 4px 13px 0px #D5E2F5",
      }}
    >
      {visibleFields.includes("Name") && (
        <div className="grid items-center grid-cols-7 py-4 below-div gap-y-4 gap-x-4">
          <label
            className="col-span-2 font-semibold text-gray-600"
            style={{ color: "#0A4464" }}
          >
            Name
          </label>
          <div className="col-span-4 ">
            <div className="flex items-center gap-x-2">
              <input
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg"
                type="text"
                name="name"
                id="name"
                onChange={(e) => {
                  const updatedValue = e.target.value;
                  setformData({ ...formData, name: updatedValue });
                }}
                value={formData?.name || ""}
                maxLength={70}
                placeholder="Enter a name"
              />
            </div>
          </div>
          <div className="col-span-1">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => removeField("Name", "name")}
            >
              <path
                d="M2.61537 16.5C2.16794 16.5 1.78685 16.3426 1.4721 16.0279C1.15737 15.7131 1 15.332 1 14.8846V2.49996H0V1.49996H4V0.730713H10V1.49996H14V2.49996H13V14.8846C13 15.3448 12.8458 15.7291 12.5375 16.0375C12.2292 16.3458 11.8449 16.5 11.3846 16.5H2.61537ZM12 2.49996H2V14.8846C2 15.0641 2.05769 15.2115 2.17308 15.3269C2.28846 15.4423 2.43589 15.5 2.61537 15.5H11.3846C11.5385 15.5 11.6795 15.4359 11.8077 15.3077C11.9359 15.1794 12 15.0384 12 14.8846V2.49996ZM4.80768 13.5H5.8077V4.49996H4.80768V13.5ZM8.1923 13.5H9.19232V4.49996H8.1923V13.5Z"
                fill="#435F6F"
              />
            </svg>
          </div>
        </div>
      )}

      {visibleFields.includes("Email") && (
        <div className="grid items-center grid-cols-7 py-4 below-div gap-y-4 gap-x-4">
          <label
            className="col-span-2 font-semibold text-gray-600"
            style={{ color: "#0A4464" }}
          >
            Email
          </label>
          <div className="col-span-4 ">
            <input
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg"
              type="text"
              name="email"
              id="email"
              onChange={(e) => {
                const updatedValue = e.target.value;
                setformData({ ...formData, email: updatedValue });
              }}
              value={formData?.email || ""}
              maxLength={70}
              placeholder="Enter an email address"
            />
          </div>
          <div className="col-span-1">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => removeField("Email", "email")}
            >
              <path
                d="M2.61537 16.5C2.16794 16.5 1.78685 16.3426 1.4721 16.0279C1.15737 15.7131 1 15.332 1 14.8846V2.49996H0V1.49996H4V0.730713H10V1.49996H14V2.49996H13V14.8846C13 15.3448 12.8458 15.7291 12.5375 16.0375C12.2292 16.3458 11.8449 16.5 11.3846 16.5H2.61537ZM12 2.49996H2V14.8846C2 15.0641 2.05769 15.2115 2.17308 15.3269C2.28846 15.4423 2.43589 15.5 2.61537 15.5H11.3846C11.5385 15.5 11.6795 15.4359 11.8077 15.3077C11.9359 15.1794 12 15.0384 12 14.8846V2.49996ZM4.80768 13.5H5.8077V4.49996H4.80768V13.5ZM8.1923 13.5H9.19232V4.49996H8.1923V13.5Z"
                fill="#435F6F"
              />
            </svg>
          </div>
        </div>
      )}
      {visibleFields.includes("Team Owners") && (
        <div className="grid items-center grid-cols-7 py-4 below-div gap-y-4 gap-x-4">
          <label
            className="col-span-2 font-semibold text-gray-600"
            style={{ color: "#0A4464" }}
          >
            Team Owners
          </label>
          <div className="col-span-4 ">
            <Select
              name="team_owner"
              id="team_owner"
              onChange={(val) => {
                setformData({ ...formData, team_owner: val });
              }}
              value={formData.team_owner}
              mode="multiple"
              required
              className="w-full "
              placeholder="Select team"
            >
              {owners
                .filter((owner) => owner.type === "team")
                .map((owner, index) => {
                  return (
                    <Select.Option
                      value={owner.id + " " + owner.type}
                      key={index}
                    >
                      <div className="flex items-center gap-x-2">
                        <span className="p-1 bg-gray-600 rounded-full">
                          <BiGlobe className="text-gray-300 text-xs" />
                        </span>
                        <span>{owner.name}</span>
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <div className="col-span-1">
            <svg
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => removeField("Team Owners", "team_owner")}
            >
              <path
                d="M2.61537 16.5C2.16794 16.5 1.78685 16.3426 1.4721 16.0279C1.15737 15.7131 1 15.332 1 14.8846V2.49996H0V1.49996H4V0.730713H10V1.49996H14V2.49996H13V14.8846C13 15.3448 12.8458 15.7291 12.5375 16.0375C12.2292 16.3458 11.8449 16.5 11.3846 16.5H2.61537ZM12 2.49996H2V14.8846C2 15.0641 2.05769 15.2115 2.17308 15.3269C2.28846 15.4423 2.43589 15.5 2.61537 15.5H11.3846C11.5385 15.5 11.6795 15.4359 11.8077 15.3077C11.9359 15.1794 12 15.0384 12 14.8846V2.49996ZM4.80768 13.5H5.8077V4.49996H4.80768V13.5ZM8.1923 13.5H9.19232V4.49996H8.1923V13.5Z"
                fill="#435F6F"
              />
            </svg>
          </div>
        </div>
      )}

      <Menu>
        <MenuButton
         className="text-xs"
          px={4}
          py={2}
          transition="all 0.2s"
          borderRadius="2xl"
          borderWidth="1px"
          borderColor={'#287199'}
          _focus={{ boxShadow: "outline" }}
        >
          Add Filter
        </MenuButton>
        <MenuList>
          {values?.map((value, index) => (
            <MenuItem  className="text-sm" key={index} onClick={() => addField(value)}>
              {value}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};

export default FilterEmployees;
