import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import AdminView from "./AdminView";
import NonAdmin from "./nonadmin";
import { useAppTouchBaseContext } from "../../components/StepByStepGuide/context";

const Touchbase = () => {
  const dispatch = useDispatch();
  const {
    setTouchBaseState,
    TouchBasestate: { run, selectedtab },
  } = useAppTouchBaseContext();
  const [selectedTab, setSelectedTab] = useState("Non-Admin");

  useEffect(() => {
    if (selectedtab) {
      setSelectedTab(selectedtab);
    }
    if (run) {
      console.log("Starting the tour");
    }
  }, [selectedtab, run]);

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "TouchBase",
        ClickLink: "company / Touchbase",
      })
    );
  }, [dispatch]);

  return (
    <div className="relative h-screen p-4 ">
      <div className="flex items-center space-x-16 px-5 ">
        <h1
          onClick={() => setSelectedTab("Non-Admin")}
          className={`cursor-pointer ${
            selectedTab === "Non-Admin" ? "text-orange-500 font-sans" : ""
          }`}
        >
          Non-Admin
        </h1>
        <h1
          onClick={() => setSelectedTab("Admin")}
          className={`cursor-pointer ${
            selectedTab === "Admin" ? "text-orange-500 font-sans" : ""
          }`}
        >
          Admin
        </h1>
      </div>
      <div className="relative mt-2 ">
        <div className="border-b-2 border-gray-300 mb-8"></div>
        <div
          className={`absolute bottom-0 transition-all duration-300 ease-in-out ${
            selectedTab === "Non-Admin" ? "left-0 w-32" : "left-36 w-20"
          } border-b-2 border-orange-500 `}
        ></div>
      </div>
      <div className="fourth_touchbase_tour">
        {selectedTab === "Non-Admin" && <NonAdmin />}
        {selectedTab === "Admin" && (
          <div
            className="flex flex-col p-4 "
            style={{
              backgroundColor: "#F7F8FA",
              borderRadius: "16px",
              width: "100%",
              height: "75vh",
            }}
          >
            <AdminView />
          </div>
        )}
      </div>
    </div>
  );
};

export default Touchbase;
