import React, { useEffect, useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import { Box, FormControl, FormLabel, Input, Spinner } from "@chakra-ui/react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { BiGlobe } from "react-icons/bi";
import { baseURL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { api } from "../../components/global/api";
import { toast, ToastContainer } from "react-toastify";
import { handleUpdatePlan } from "../../stores/slices/userAuthSlice";

const RoleAccess = ({ fetchRole, assignRole }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const [teams, setTeams] = useState([]);
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const plan = user?.currentPlan;
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingCollaborate, setLoadingCollaborate] = useState(false);
  const currentEmployee = currentUser?.employees;
  const [users, setUsers] = useState(null);
  const [reportRoles, setReportRoles] = useState([]);
  const [formData, setformData] = useState({
    email: null,
    assign_team: [],
    assign_role: null,
    report_to: null,
    inTeams: [],
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const fetchEmployees = async () => {
    try {
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      const response = await api.get(baseURL + "/employee_details/", {
        company_id: company_id,
      });
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const getTeams = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      const response = await api.get(baseURL + "/create-team/", {
        company_id: company_id,
      });
      setTeams(response?.data);
    } catch (error) {
      //  setError(error);
      console.log(error);
    }
  };

  const [emailError, setEmailError] = useState(false);

  function changeHandeler(event) {
    const { name, value } = event.target;
    setEmailError(!validateEmail(value));
    setformData((prevFormData) => ({
      ...prevFormData,
      email: value,
    }));
  }

  const fetchReportRoles = async (e) => {
    try {
      const res = await api.post(baseURL + "/report-to/", {
        role_id: e,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
      });
      setReportRoles(res?.data);
    } catch (error) {}
  };

  const getCurrentPlanData = async () => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const result = await api.get(baseURL + "/subscription/", {
        company_id: currentCompany?.company_id,
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleInviteMember = async () => {
    if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
      // dispatch(handleOpenPlanModal());
      return;
    }
    setLoadingInvite(true);
    if (!formData?.email) {
      return;
    }

    if (emailError) {
      return;
    }

    if (formData?.assign_role === "") {
      return;
    }

    if (formData?.assign_team === "") {
      return;
    }
    if (!formData?.report_to) {
      return;
    }

    try {
      const payload = {
        email: formData?.email,
        employee_role_id: formData?.assign_role,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        teams: formData?.assign_team,
        report_to: formData?.report_to,
      };
      const res = await api.post(baseURL + "/send-email/", payload);
      setLoadingInvite(false);
      setformData({
        email: "",
        assign_role: "",
        assign_team: [],
        report_to: "",
      });
      if (res?.status === 200) {
        toast.success("User has been Invited.");
        getCurrentPlanData();
      }
      else if (res?.status == 404) {
        toast.error("Employee already exists in the company");
        setformData({
          email: "",
          assign_role: "",
          assign_team: [],
          report_to: "",
        });
      }
    } catch (error) {
      // console.log(error)
      setLoadingInvite(false);
      if (error?.response?.status == 404) {
      } else {
        setformData({
          email: "",
          assign_role: "",
          assign_team: [],
          report_to: "",
        });
        toast.error("Invite user failed. Please try again");
      }
    }
  };

  const handleSendCollaborate = async () => {
    if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
      // dispatch(handleOpenPlanModal());
      return;
    }
    setLoadingCollaborate(true);
    if (!formData?.user?.user_id) {
      return;
    }
    if (formData?.add_to_team === "") {
      return;
    }

    try {
      const payload = {
        employee_user_id: formData?.user?.user_id,
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        team_id: formData?.add_to_team,
      };
      const res = await api.post(baseURL + "/team-colab/", payload);
      setLoadingCollaborate(false);
      setformData({
        user: "",
        add_to_team: "",
        inTeams: null,
      });
      if (res.status === 201) {
        toast.success("User has been Invited.");
      }
    } catch (error) {
      setLoadingCollaborate(false);
      if (error?.response?.status == 404) {
        setformData({
          user: "",
          add_to_team: "",
          inTeams: null,
        });
        toast.error("Invite user failed. Please try again");
      }
    }
  };

  const handleSelectChange = (selectedEmail) => {
    const selectedUser = users.find((user) => user.email === selectedEmail);
    setformData((prevFormData) => ({
      ...prevFormData,
      user: selectedUser || null,
      inTeams: selectedUser?.teams?.map((team) => team.team_id),
    }));
  };

  useEffect(() => {
    getTeams();
    fetchRole();
  }, []);

  return (
    <div className="overflow-y-auto w-full">
      <style>
        {`
          .custom-select .ant-select-selector {
            border: none !important;
            background-color: transparent !important;
          }

          .custom-select .ant-select-item {
            border: none !important;
             background-color: transparent !important;
          }

          .custom-select .ant-select-dropdown {
            border: none !important;
             background-color: transparent !important;
          }
        `}
      </style>
      <CustomScrollbar>
        <div className="p-6 bg-white rounded-lg shadow-lg space-y-10 font-sans">
          <div className="space-y-2">
            <h1 className="text-3xl font-semibold">User Management</h1>
            <p className="text-gray-600 font-medium">
              Manage your team members and their account permissions here.
            </p>
          </div>

          <div className="border-t border-gray-300"></div>
          <div className="flex items-end justify-between space-x-5">
            <div className="space-y-1 w-1/3 ">
              <h1 className="text-2xl font-semibold">Invite Members</h1>
              <p className="text-gray-500 text-base">
                Get your projects up and running faster by inviting your team
                members.
              </p>
            </div>

            <input
              type="email"
              placeholder="john@example.com"
              value={formData?.email}
              onChange={(e) => {
                setEmailError(false);
                changeHandeler(e);
              }}
              className={`flex-1 bg-transparent text-gray-700 h-12 ${
                emailError ? "border-red-500 border-2" : ""
              }`}
            />

            <Box width="12rem">
              <FormControl id="team">
                <div className="flex flex-col">
                  <FormLabel>
                    <p className="text-sm text-gray-500">Team</p>
                  </FormLabel>
                  <Select
                    name="members"
                    id="members"
                    style={{
                      overflowY: "auto",
                    }}
                    className="custom-select h-12 w-full no-arrow border cursor-not-allowed  rounded-md"
                    onChange={(value) => {
                      setformData({ ...formData, assign_team: value });
                    }}
                    value={formData?.assign_team}
                    required
                    mode="multiple"
                    placeholder="Select Member"
                    suffixIcon={null}
                  >
                    {teams?.map((team, index) => {
                      return (
                        <Select.Option
                          value={team?.team_id || team?.tema_id}
                          key={index}
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="p-1 bg-gray-600 rounded-full flex-shrink-0">
                              <BiGlobe className="text-gray-300 text-xs" />
                            </span>
                            <span className="whitespace-nowrap">
                              {team?.team_name}
                            </span>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </FormControl>
            </Box>

            <Box width="10rem">
              <FormControl id="role">
                <FormLabel>
                  <p className="text-sm text-gray-500">Role</p>
                </FormLabel>
                <Select
                  className="h-12 w-full"
                  placeholder=""
                  name="assign_role"
                  id="assign_role"
                  onChange={(e) => {
                    setformData({ ...formData, assign_role: e, report_to: "" });
                    fetchReportRoles(e);
                  }}
                  value={formData?.assign_role}
                >
                  {assignRole?.map((assign_role, index) => {
                    return (
                      <Select.Option
                        value={assign_role?.role_id}
                        key={index}
                        disabled={assign_role?.role_name === "Admin"}
                      >
                        <span className="capitalize">
                          {assign_role?.role_name}
                        </span>
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box width="10rem">
              <FormControl id="role">
                <FormLabel>
                  <p className="text-sm text-gray-500">Report To</p>
                </FormLabel>
                <Select
                  className="h-12 w-full"
                  placeholder=""
                  name="report_to"
                  id="report_to"
                  onChange={(e) => {
                    setformData({ ...formData, report_to: e });
                  }}
                  value={formData?.report_to}
                >
                  {reportRoles?.map((role, index) => {
                    return (
                      <Select.Option value={role?.employee_id} key={index}>
                        <span className="capitalize">
                          {role?.role_name} , {role?.name}
                        </span>
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="flex justify-end">
            <button
              className={`bg-[#0081F9] text-white rounded-lg p-2 px-6 flex items-center space-x-2 ${
                !(
                  formData?.assign_role &&
                  formData?.email &&
                  formData?.assign_team
                )
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={handleInviteMember}
              disabled={
                !(
                  formData?.assign_role &&
                  formData?.email &&
                  formData?.assign_team
                )
              }
            >
              <EmailOutlinedIcon />
              <p className="font-semibold">Send Invite</p>
              {loadingInvite && (
                <Spinner size="sm" color="white" className="ml-2" />
              )}
            </button>
          </div>

          <div className="border-t border-gray-300"></div>
          <div className="flex items-end justify-between space-x-5">
            <div className="space-y-1 w-1/3 ">
              <h1 className="text-2xl font-semibold">Team Collaboration</h1>
              <p className="text-gray-500 text-base">
                Accelerate your projects by bringing your team on board to
                collaborate.
              </p>
            </div>
            <Box width="12rem">
              <FormControl id="team">
                <FormLabel>
                  <p className="text-sm text-gray-500">Select Email</p>
                </FormLabel>
                <Select
                  placeholder="Select a user"
                  onChange={handleSelectChange}
                  className="h-12 w-full"
                  value={formData.user?.email || ""}
                >
                  {users?.map((user) => (
                    <Select.Option key={user?.employee_id} value={user?.email}>
                      {user?.email}
                    </Select.Option>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box width="12rem">
              <FormControl id="team">
                <div className="flex flex-col">
                  <FormLabel>
                    <p className="text-sm text-gray-500">Team</p>
                  </FormLabel>
                  <Select
                    style={{
                      overflowY: "auto",
                      backgroundColor: "#F3F4F6",
                    }}
                    className="custom-select h-12 w-full no-arrow border cursor-not-allowed  rounded-md"
                    name="team"
                    id="team"
                    mode="multiple"
                    suffixIcon={null}
                    filterOption={false}
                    value={formData?.inTeams}
                    open={false}
                    disabled
                    tagRender={(props) => {
                      const { label, value } = props;
                      return (
                        <div className="flex border-none  items-center bg-gray-300 p-1  ml-2">
                          <span className="p-1 bg-gray-600 rounded-full">
                            <BiGlobe className="text-gray-300 text-xs" />
                          </span>
                          <span className="text-gray-500 ml-2">{label}</span>
                        </div>
                      );
                    }}
                    dropdownRender={(menu) => (
                      <div className="overflow-y-auto bg-gray-200 border-none">
                        {menu}
                      </div>
                    )}
                  >
                    {formData?.user?.teams?.map((team) => (
                      <Select.Option
                        className="bg-gray-100 border-none"
                        value={team?.team_id}
                        key={team?.team_id}
                      >
                        <span className="capitalize">{team?.team_name}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </FormControl>
            </Box>

            <Box width="12rem">
              <FormControl id="team">
                <FormLabel>
                  <p className="text-sm text-gray-500">Collaborate With</p>
                </FormLabel>
                <Select
                  name="members"
                  id="members"
                  onChange={(value) => {
                    setformData({ ...formData, add_to_team: value });
                  }}
                  value={formData?.add_to_team}
                  required
                  className="h-12 w-full"
                  placeholder="Select Teams"
                >
                  {teams?.map((team, index) => {
                    const isDisabled = formData?.inTeams?.includes(
                      team?.team_id || team?.tema_id
                    );

                    return (
                      <Select.Option
                        value={team?.team_id || team?.tema_id}
                        key={index}
                        disabled={isDisabled}
                      >
                        <div className="flex items-center gap-2">
                          <span className="p-1 bg-gray-600 rounded-full">
                            <BiGlobe className="text-gray-300 text-xs" />
                          </span>
                          <span>{team?.team_name}</span>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="flex justify-end">
            <button
              className={`bg-[#0081F9] text-white rounded-lg p-2 px-6 flex items-center space-x-2 ${
                !(formData?.user?.employee_id && formData?.add_to_team)
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={!(formData?.user?.employee_id && formData?.add_to_team)}
              onClick={handleSendCollaborate}
            >
              <EmailOutlinedIcon />
              <p className="font-semibold">Send Invite</p>
              {loadingCollaborate && (
                <Spinner size="sm" color="white" className="ml-2" />
              )}
            </button>
          </div>
        </div>
      </CustomScrollbar>
      <ToastContainer />
    </div>
  );
};

export default RoleAccess;
