import { Spin } from 'antd';
import React from 'react'

const LoadingPage = ({ loading }) => {
    if (loading) return (

            <div className="top-0 left-0 h-full w-full absolute flex justify-center items-center mx-auto">
                {/* <Spin tip="Loading">
                    <div className="content" />
                </Spin> */}
                <img src="/assets/images/Group 3.svg" />
            </div>
    )
    return null;
}

export default LoadingPage