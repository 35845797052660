import { Modal, TreeSelect } from "antd";
import React, { useEffect, useState } from "react";
import { GoGoal } from "react-icons/go";
import {
  handleCloseMoveOkrModal,
  handleCloseOkrDrawer,
} from "../../../stores/slices/okrSlice";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../global/api";

const MoveOkrModal = ({ goalName, drawerData, getOkrs }) => {
  const [formData, setformData] = useState({});
  const [parentDropdownItems, setParentDropdownItems] = useState([]);
  const okrSlice = useSelector((state) => state.okr);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const getParentData = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const response = await api.get(baseURL + "/assignparents/", {
        company_id: currentEmployee?.company_id,
        session: okrSlice?.session,
      });
      const tempParentTree = response.data?.map((goal) => {
        return {
          label: (
            <p className="flex gap-x-2 items-center">
              <button
                className="rounded p-1"
                style={{
                  background:
                    "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                }}
              >
                <GoGoal
                  className="text-md"
                  style={{
                    color: "#B91C1C",
                    width: "18px",
                    height: "18px",
                  }}
                />
              </button>
              {goal?.title}
            </p>
          ),
          value: goal?.goal_id + " " + "goal",
          type: "goal",
          disabled: goal?.goal_id === drawerData?.goal_id,
        };
      });
      setParentDropdownItems(tempParentTree);
    } catch (error) {}
  };

  useEffect(() => {
    if (okrSlice?.openMoveOkrModal) getParentData();
  }, [okrSlice?.openMoveOkrModal]);

  const moveGoal = async () => {
    const okr_id = drawerData?.okr_id;
    const moveData = formData?.parent_val;
    const splitMoveData = moveData?.split(" ");
    if (splitMoveData?.length !== 2) return;

    const parent_id = splitMoveData[0];
    const parent_type = splitMoveData[1];
    try {
      const response = await api.post(baseURL + "/move-jobs/", {
        current_id: okr_id,
        current_type: "okr",
        target_id: parent_id,
        target_type: parent_type,
      });
      getOkrs();
      dispatch(handleCloseMoveOkrModal(false));
      dispatch(handleCloseOkrDrawer(false));
    } catch (error) {}
  };
  const checkTypeAndSave = (newVal) => {
    if (!newVal) return;
    const splitData = newVal?.split(" ");
    if (splitData?.length !== 2) return;
    if (splitData[1] !== "goal") {
      toast.warn("Please select a goal");
      return;
    }
    const parent_id = splitData[0];
    const parent_type = splitData[1];
    setformData({
      ...formData,
      parent_val: newVal,
      parent_id: parent_id,
      parent_type: parent_type,
    });
  };

  return (
    <Modal
      zIndex={3}
      open={okrSlice?.openMoveOkrModal}
      onCancel={() => dispatch(handleCloseMoveOkrModal(false))}
      okText="Move"
      onOk={moveGoal}
    >
      <div className="w-full grid grid-cols-6 gap-x-4 gap-y-4 p-6">
        <h3 className="col-span-6 text-2xl font-semibold ">Move Okr</h3>
        <div className="col-span-6 flex gap-x-2 items-center">
          <button>
            <img
              src="/assets/images/objective.svg"
              style={{
                width: "27px",
                height: "27px",
              }}
            />
          </button>
          {drawerData?.title}
        </div>
        <div className="col-span-6 flex flex-col gap-2 mt-2 w-full">
          <div className="col-span-6 text-xs">Move To</div>
          <TreeSelect
            showSearch
            size="large"
            className="w-full"
            value={formData?.parent_val}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
            placeholder="Assign Parent"
            allowClear
            // defaultValue={drawerData?.parent_id+" "+"goal"}
            treeDefaultExpandAll
            onChange={(newVal) => checkTypeAndSave(newVal)}
            // onBlur={(e) => updateParent(e)}
            treeData={parentDropdownItems}
          />
        </div>
      </div>
      <ToastContainer />
    </Modal>
  );
};

export default MoveOkrModal;
