import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { getAvatarColor, getInitials } from "../../../utils/helpers";
import { MdGroups } from "react-icons/md";
import CustomScrollbar from "../../global/CustomScrollBar";
import { FaRegEdit } from "react-icons/fa";
import EditEmployeeModal from "../../../pages/People/EditEmployeeModal";

export const Tables = ({ data, fetchData, handleDeleteEmployee }) => {
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleOpenEditModal = (data) => {
    setModalData(data); // Store the data in state
    setModalVisible(true); // Open the modal
  };

  const handleCancel = () => {
    setModalVisible(false); // Close the modal
  };

  return (
    <div className="rounded-lg h-full">
      <div className="mx-auto p-3">
        <CustomScrollbar>
          <table className="min-w-full bg-white p-6 rounded-lg">
            <thead
              className="border-b text-left bg-header text-white"
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "8px",
              }}
            >
              <tr className="rounded" style={{ textAlign: "left" }}>
                <th className="px-6 py-3 text-left">Name</th>
                <th className="px-6 py-3 text-left">Role</th>
                <th className="px-6 py-3 text-left">Report To</th>
                <th className="px-6 py-3 text-left">Team</th>
                <th className="px-6 py-3 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="border-b transition-all hover:bg-gray-100"
                  >
                    {/* Name column */}
                    <td className="py-3 px-6 flex items-center">
                      <div className="flex items-center gap-2">
                        <div
                          className="w-8 h-8 text-sm border border-black rounded-full flex items-center justify-center text-white"
                          style={{
                            backgroundColor: item?.profile_image
                              ? "initial"
                              : getAvatarColor(item?.username),
                          }}
                        >
                          {item?.profile_image ? (
                            <img
                              src={item?.profile_image}
                              alt="Profile"
                              className="rounded-full w-full h-full object-cover"
                            />
                          ) : (
                            getInitials(item?.username)
                          )}
                        </div>
                        <span>{item?.username}</span>
                      </div>
                    </td>

                    <td className="py-3 px-6 text-left">{item?.rolename}</td>
                    <td className="py-3 px-6 text-left">
                      {item?.report_to_name}
                    </td>

                    {/* Team column */}
                    <td className="py-3 px-6">
                      <ul className="flex flex-col gap-1">
                        {item?.teams?.map((team, index) => (
                          <li className="flex items-center gap-2" key={index}>
                            <span
                              className="p-1 rounded-full"
                              style={{ backgroundColor: "#063F5D" }}
                            >
                              <MdGroups className="text-white" size={14} />
                            </span>
                            <span
                              className="font-medium"
                              style={{
                                color: team?.isManager ? "#612BD1" : "#BB1D72",
                              }}
                            >
                              {team?.team_name}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </td>

                    {/* Actions column */}
                    <td className="py-3 px-6 text-center gap-x-2">
                      <Tooltip
                        title={
                          item?.rolename === "Admin"
                            ? "You cannot edit this employee"
                            : "Edit Employee Details"
                        }
                      >
                        <button
                          onClick={() => {
                            handleOpenEditModal(item);
                          }}
                          disabled={item?.rolename === "Admin"}
                          className={` transition-all rounded-lg text-lg ${
                            user?.roleAccess?.Teams?.Update
                              ? "cursor-pointer"
                              : "text-black/50 cursor-not-allowed"
                          }`}
                        >
                          <FaRegEdit />
                        </button>
                      </Tooltip>
                      <Tooltip
                        title={
                          item?.rolename === "Admin"
                            ? "Cannot delete Admin"
                            : !item?.is_delete
                            ? "Cannot delete this employee until users reporting to them are removed."
                            : "Delete Employee"
                        }
                      >
                        <button
                          onClick={() => {
                            if (item?.is_delete) {
                              handleDeleteEmployee(item?.employee_id);
                            }
                          }}
                          className={` px-2 py-1 transition-all rounded-lg `}
                          disabled={
                            item?.rolename === "Admin" || !item?.is_delete
                          }
                        >
                          <svg
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`${
                              item?.rolename === "Admin" || !item?.is_delete
                                ? "text-red-200 cursor-not-allowed"
                                : "text-red-600 cursor-pointer"
                            }`}
                          >
                            <path
                              d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CustomScrollbar>
      </div>
      {modalVisible && (
        <EditEmployeeModal
          data={modalData}
          modalOpen={modalVisible}
          fetchData={fetchData}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};
