import React from "react";


const NotFound = () => {
  return (
    <div className="h-screen flex justify-center items-center flex-col p-4">
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="w-full md:w-96">
          <h1 className="font-bold text-center mb-4">Page Not Found</h1>

          <p className="text-md text-center mb-4 font-semibold opacity-60">
            Sorry, we could'nt find this page.But don’t worry , you can find
            plenty of things on our{" "}
            <a href="/home" className="text-blue-500 hover:underline">
              homePage
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
