import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import CookiePolicy from "./CookiePolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./Terms&Services";

const Support = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Cookie Policy");

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "Help&Support",
        linkLocation: `help&support/${selectedTab}`,
        ClickLink: `help&support / ${selectedTab}`,
      })
    );
  }, [dispatch, selectedTab]);

  const tabs = ["Cookie Policy", "Privacy Policy", "Terms and Services"];

  return (
    <div className="relative h-screen p-4">
      <div className="flex items-center space-x-16 px-5">
        {tabs?.map((tab) => (
          <h1
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`relative cursor-pointer ${
              selectedTab === tab ? "text-orange-500 font-sans" : ""
            }`}
          >
            {tab}
            {selectedTab === tab && (
              <span className="absolute left-0 bottom-[-10px] w-full h-[1px] bg-orange-500 transition-all duration-300 ease-in-out"></span>
            )}
          </h1>
        ))}
      </div>
      <div className="border-b-2 border-gray-300 mb-8 mt-2"></div>

      <div
        className="flex flex-col p-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        {selectedTab === "Cookie Policy" && <CookiePolicy />}
        {selectedTab === "Privacy Policy" && <PrivacyPolicy />}
        {selectedTab === "Terms and Services" && <TermsOfService />}
      </div>
    </div>
  );
};

export default Support;
