import React, { useState } from "react";

const CustomRecurrenceModal = ({ isOpen, handleClose, onSave }) => {
  const [recurrenceType, setRecurrenceType] = useState("Day");
  const [customFrequency, setCustomFrequency] = useState(1);
  const [selectedOption, setSelectedOption] = useState("never");
  const [selectedDays, setSelectedDays] = useState([]);
  const [weekDay, setWeekDay] = useState("");
  const [weekDayofMonth, setWeekDayofMonth] = useState("");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [executions, setExecutions] = useState(1);

  const daysOfWeek = {
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday",
    6: "Sunday",
  };

  const handleDaySelection = (day) => {
    const dayName = daysOfWeek[day];
    if (selectedDays.includes(dayName)) {
      setSelectedDays(selectedDays.filter((d) => d !== dayName));
    } else {
      setSelectedDays([...selectedDays, dayName]);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.id);
  };

  const handleSave = () => {
    // Gather all recurrence data
    const recurrenceData = {
      custom_frequency: customFrequency,
      custom_unit: recurrenceType?.toLowerCase(),
      repeat_on_days: selectedDays,
      end_condition: selectedOption,
      end_date: selectedOption === "on_date" ? endDate : null,
      occurrences: selectedOption === "after_occurrences" ? executions : null,
      on_week_of_month: weekDay,
      weekday_of_month: [weekDayofMonth],
    };
    onSave(recurrenceData);
    handleClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
      <div className="bg-white rounded-lg shadow-lg w-full max-w-sm p-6 relative">
        <h2 className="text-xl font-semibold mb-4">Custom Settings</h2>
        <div className="space-y-4">
          <div className="flex space-x-2">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Repeat every
            </label>
            <div className="flex space-x-2 justify-center items-end">
              <input
                type="number"
                className="w-1/3 h-2/3 p-2 border border-gray-300 rounded-md"
                value={customFrequency}
                onChange={(e) => setCustomFrequency(Number(e.target.value))}
              />
              <select
                className="w-1/2 h-2/3 p-1 border border-gray-300 rounded-md"
                value={recurrenceType}
                onChange={(e) => setRecurrenceType(e.target.value)}
              >
                <option value="Day">Day</option>
                <option value="Week">Week</option>
                <option value="Month">Month</option>
                <option value="Year">Year</option>
              </select>
            </div>
          </div>

          {recurrenceType === "Week" && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Repeat on
              </label>
              <div className="flex space-x-2">
                {Object.keys(daysOfWeek).map((day, index) => (
                  <button
                    key={index}
                    onClick={() => handleDaySelection(day)}
                    className={`p-2 w-8 h-8 flex items-center justify-center border border-gray-300 rounded-full ${
                      selectedDays.includes(daysOfWeek[day])
                        ? "bg-blue-500 text-white"
                        : "bg-gray-100 text-gray-700"
                    }`}
                  >
                    {daysOfWeek[day][0]}
                  </button>
                ))}
              </div>
            </div>
          )}

          {recurrenceType === "Month" && (
            <div className="flex space-x-2">
              <label className="block text-sm font-medium text-gray-700 mb-2 ">
                On
              </label>
              <div
                value={weekDay}
                onChange={(e) => setWeekDay(e.target.value)}
                className="flex space-x-2 justify-center items-end"
              >
                <select className=" p-1 border border-gray-300 rounded-md">
                  <option value="1st">1st Day</option>
                  <option value="2nd">2nd Day</option>
                  <option value="3rd">3rd Day</option>
                  <option value="4th">4th Day</option>
                  <option value="last">Last Day</option>
                </select>
                <select
                  value={weekDayofMonth}
                  onChange={(e) => setWeekDayofMonth(e.target.value)}
                  className="p-1 border border-gray-300 rounded-md"
                >
                  <option value="monday">Monday</option>
                  <option value="tuesday">Tuesday</option>
                  <option value="wednesday">Wednesday</option>
                  <option value="thursday">Thursday</option>
                  <option value="friday">Friday</option>
                  <option value="saturday">Saturday</option>
                  <option value="sunday">Sunday</option>
                </select>
              </div>
            </div>
          )}

          <div>
            <label className="text-sm font-medium text-gray-700 mb-2">
              End
            </label>
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <input
                  type="radio"
                  id="never"
                  name="end"
                  className="mr-2 h-4 w-4"
                  checked={selectedOption === "never"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="never" className="text-sm">
                  Never
                </label>
              </div>
              <div className="flex items-center space-x-2">
                <input
                  type="radio"
                  id="on_date"
                  name="end"
                  className="mr-2 h-4 w-4"
                  checked={selectedOption === "on_date"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="on_date" className="text-sm">
                  On
                </label>
                <input
                  type="date"
                  backgroundColor="#F7F8FA"
                  className={`ml-2 p-1 w-1/3 border border-gray-300 rounded-md ${
                    selectedOption !== "on_date"
                      ? "cursor-not-allowed bg-gray-200"
                      : ""
                  }`}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  disabled={selectedOption !== "on_date"}
                />
              </div>
              <div className="flex items-center space-x-2">
                <input
                  type="radio"
                  id="after_occurrences"
                  name="end"
                  className="mr-2 h-4 w-4"
                  checked={selectedOption === "after_occurrences"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="after_occurrences" className="text-sm">
                  After
                </label>
                <input
                  type="number"
                  backgroundColor="#F7F8FA"
                  className={`ml-2 p-2 w-16 border border-gray-300 rounded-md ${
                    selectedOption !== "after_occurrences"
                      ? "cursor-not-allowed bg-gray-200"
                      : ""
                  }`}
                  value={executions}
                  onChange={(e) => setExecutions(Number(e.target.value))}
                  disabled={selectedOption !== "after_occurrences"}
                />
                <label className="text-sm">Executions</label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomRecurrenceModal;
