import React from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";

const KPIMetrics = () => {
  return (
    <div className="overflow-y-auto">
      <CustomScrollbar>
        <div className="p-4 bg-white rounded-lg w-full space-y-5 font-sans">
          Coming Soon
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default KPIMetrics;
