import React from "react";
import { Avatar, Progress } from "antd";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { BiGlobe } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleOpenOkrDrawer } from "../../stores/slices/okrSlice";

const OkrReportsTable = ({ okrReports }) => {
  const dispatch = useDispatch();
  const roleAccess = useSelector((state) => state?.user?.roleAccess);

  const handleOpenDrawerWithKey = (okr_id) => {
    if (roleAccess?.Objective?.View) {
      dispatch(handleOpenOkrDrawer(okr_id));
    }
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-4 w-full p-4 sticky bg-header text-white">
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Name</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Owner(s)</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Progress</h3>
        </div>
        <div className="flex items-center col-span-1 justify-center">
          <h3 className="text-lg font-medium">Description</h3>
        </div>
      </div>
      <div className="">
        {okrReports?.map((item, index) => (
          <div
            key={index}
            className={`grid grid-cols-4 w-full px-2 border border-b h-auto py-4 text-basic font-medium text-base`}
            // style={{
            //   boxShadow: "2px 4px 8px 1px #E7EEF9",
            // }}
          >
            <div className="flex items-center ml-5 justify-start gap-x-2 col-span-1 w-full px-2">
              <Link onClick={() => handleOpenDrawerWithKey(item?.okr_id)}>
                {item?.title}
              </Link>
            </div>

            <div className="flex flex-col w-full items-center justify-center gap-x-2 col-span-1">
              <Avatar.Group
                maxCount={2}
                size={"small"}
                maxPopoverTrigger="click"
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  display: "flex",
                  flexDirection: "column !important",
                }}
              >
                {item?.owners?.map((owner, index) =>
                  owner?.type === "user" ? (
                    <div
                      key={index}
                      className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                      style={{
                        backgroundColor: owner?.profile_image
                          ? "initial"
                          : getAvatarColor(owner?.name),
                      }}
                      size={18}
                    >
                      {owner?.profile_image ? (
                        <img
                          src={owner?.profile_image}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                            display: "block",
                          }}
                        />
                      ) : (
                        getInitials(owner.name)
                      )}
                    </div>
                  ) : (
                    <span key={index} className="p-1 bg-gray-600 rounded-full">
                      <BiGlobe className="text-gray-300 text-xs" />
                    </span>
                  )
                )}
              </Avatar.Group>
            </div>
            <div className="flex items-center justify-center gap-x-2 col-span-1">
              <Progress
                type="circle"
                size={50}
                percent={item?.overall_gain}
                strokeWidth={10}
                strokeColor={
                  item?.overall_gain >= 0 && item?.overall_gain <= 29
                    ? "#FF2934"
                    : item?.overall_gain >= 30 && item?.overall_gain <= 49
                    ? "#FF8400"
                    : item?.overall_gain >= 50 && item?.overall_gain <= 69
                    ? "#FFF000"
                    : "#53DC0D"
                }
                trailColor="#f0f0f0"
                format={(percent) => (
                  <span style={{ fontWeight: "medium", color: "black" }}>
                    {percent}%
                  </span>
                )}
              />
            </div>
            <div
              className="flex p-2  items-start gap-x-2 col-span-1"
              style={{
                border: "0.96px solid rgba(168, 175, 178, 0.72)",
                borderRadius: "7.7px",
              }}
            >
              {item?.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OkrReportsTable;
