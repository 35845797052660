import { useSelector } from "react-redux";
import { Sessions, Units } from "./Constants";

export function getInitials(fullName) {
  // Split the full name into an array of words
  if (!fullName) {
    return "NA";
  }
  const nameArray = fullName.split(" ");

  if (nameArray.length >= 2) {
    const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
    const lastNameInitial = nameArray[nameArray.length - 1]
      .charAt(0)
      .toUpperCase();

    // Return the concatenated initials
    return `${firstNameInitial}${lastNameInitial}`;
  } else if (nameArray.length === 1) {
    // Take the first letter of the first name and make it uppercase
    const firstNameInitial = nameArray[0].charAt(0).toUpperCase();

    // Return the initial
    return firstNameInitial;
  } else {
    // Handle the case where there are not enough parts in the name
    return "NA";
  }
}

export function formatDate() {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const today = new Date();
  return today?.toLocaleDateString("en-GB", options);
}

export function getDaysDifferenceFromDate(givenDateString) {
  const givenDate = new Date(givenDateString);
  const currentDate = new Date();

  const differenceInMilliseconds = currentDate - givenDate;
  const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

  return Math.round(Math.abs(differenceInDays));
}
const colors = [
  "#ff0000",
  "#00ff00",
  "#0000ff",
  "#ffff00",
  "#ff00ff",
  "#00ffff",
  "#ff8000",
  "#8000ff",
  "#0080ff",
  "#ff0080",
  "#80ff00",
  "#008000",
  "#800000",
  "#808000",
  "#008080",
  "#800080",
  "#808080",
  "#ff8080",
  "#80ff80",
  "#8080ff",
  "#ff80ff",
  "#80ffff",
  "#ff80ff",
  "#ffff80",
  "#80ff80",
  "#ff80ff",
];
export function getAvatarColor(initial) {
  if (!initial) {
    return colors[0];
  }
  const charCode = initial.toUpperCase().charCodeAt(0);
  const index = (charCode - 65) % colors.length; // Mapping A-Z to colors
  return colors[index];
}

export const getNameBySessionId = (sessionId) => {
  const sessionLabel = Sessions.find((session) => session.value === sessionId);
  return sessionLabel?.label || "All";
};
export const getFormatedDate = (date) => {
  var targetDate = new Date(date);

  // Format the date
  var formattedDate = targetDate?.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  return formattedDate;
};
function getMonthAbbreviation(month) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[month - 1];
}
export const getFormatedDateAndMonth = (date) => {
  var dateObject = new Date(date);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const resultString = `${day} ${getMonthAbbreviation(month)}`;
  return resultString;
};

export const getUnitValue = (label) => {
  const unitValue = Units.find((unit) => unit.label == label);
  return unitValue?.value || label;
};

export function timeAgo(date) {
  const dateObject = new Date(date);
  const seconds = Math.floor((new Date() - dateObject) / 1000);

  let interval = Math.floor(seconds / 60);
  if (interval < 1) {
    return `${Math.abs(seconds)} seconds ago`;
  }
  if (interval < 60) {
    return `${interval} minutes ago`;
  }

  interval = Math.floor(interval / 60);
  if (interval < 24) {
    return `${interval} hours ago`;
  }

  interval = Math.floor(interval / 24);
  if (interval < 30) {
    return `${interval} days ago`;
  }

  // If the time difference is greater than 30 days, display the actual date.
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date?.toLocaleDateString("en-US", options);
}

export const employeeDeleterivilageChecker = (user, currentUser, employee) => {
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  if (currentEmployee?.role === "Admin") {
    if (employee?.role === "user") {
      return {
        isPossible: true,
        message: "You can remove this User from your company",
      };
    } else {
      return { isPossible: false, message: "You Cannot delete Admin" };
    }
  } else {
    return {
      isPossible: false,
      message: "You cannot delete employees",
    };
  }
};
export const checkTeamPriveledge = (user, currentUser, team) => {
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  if (currentEmployee?.role === "Admin") {
    return {
      isPossible: true,
      messageDelete: "You can delete team",
      messageEdit: "You can edit team",
    };
  } else {
    return {
      isPossible: false,
      messageDelete: "You cannot delete team",
      messageEdit: "You cannot edit team",
    };
  }
};

export const getCurrentEmployee = (user, currentUser) => {
  return currentUser?.employees[user?.currentCompany];
};
