import { createContext, useContext, useMemo } from "react";
import { useSetState } from "react-use";

const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const AppContext = createContext({
  state: appState,
  setState: () => undefined,
});
export const AppTouchBaseContext = createContext({
  TouchBasestate: appState,
  setTouchBaseState: () => undefined,
});

AppContext.displayName = "AppContext";
AppTouchBaseContext.displayName = "AppTouchBaseContext";

export function AppProvider(props) {
  const [state, setState] = useSetState(appState);
  const [TouchBasestate, setTouchBaseState] = useSetState(appState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state]
  );
  const touchBaseValue = useMemo(
    () => ({
      TouchBasestate,
      setTouchBaseState,
    }),
    [TouchBasestate, setTouchBaseState]
  );

  return (
    <AppContext.Provider value={value}>
      <AppTouchBaseContext.Provider value={touchBaseValue}>
        {props.children}
      </AppTouchBaseContext.Provider>
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }

  return context;
}

export function useAppTouchBaseContext() {
  const context = useContext(AppTouchBaseContext);
  if (!context) {
    throw new Error(
      "useAppTouchBaseContext must be used within an AppProvider"
    );
  }
  return context;
}
