import React from "react";
import {
  getAvatarColor,
  getFormatedDate,
  getInitials,
} from "../../utils/helpers";
import { CiClock2, CiEdit, CiFaceSmile } from "react-icons/ci";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoBookOutline } from "react-icons/io5";
import { FaRegCommentDots } from "react-icons/fa";
import { baseURL } from "../../utils/config";
import { useSelector } from "react-redux";
import { api } from "../global/api";

const UpdateKeyResultCard = ({
  iteam,
  index,
  dataUpdated,
  setUpdate_key_id,
}) => {
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const user= useSelector(state=>state?.user)
  const currentEmployee = currentUser?.employees[user?.currentCompany];


  const handleDeleteUpdate = async () => {
    try {
      const res = await api.delete(baseURL + `/update-key/`, {
          update_key_id: iteam?.update_key_id,
      });
      dataUpdated();
    } catch (error) {}
  };
  return (
    <div key={index} className="">
      <div className="flex items-center gap-4  border justify-between border-gray-200 p-2 rounded ">
        <div className="flex items-center justify-center ">
          <div
            className="w-8 h-8 text-sm border border-black rounded-full flex items-center justify-center text-white  relative"
            style={{
                    backgroundColor: iteam?.profile_image
                      ? "initial"
                      : getAvatarColor(iteam?.username),
                  }}
            size={18}
          >
            {iteam?.profile_image ? (
              <img
                src={iteam.profile_image}
                alt="Profile"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                  display: "block",
                }}
              />
            ) : (
              getInitials(iteam?.username)
            )}
          </div>
          <h3 className="p-2 text-xs font-semibold">
            {getFormatedDate(iteam?.changed_at)}
          </h3>
          <span>
            <CiClock2
              className=" text-d8dfea text-sm"
              style={{ color: "#FFA500" }}
            />
          </span>
        </div>

        <div className="flex gap-x-4 items-center">
          <div className="w-full px-1 text-white bg-green-400 rounded">
            {/* High */}
            {iteam?.confidence_level}
          </div>
          {/* <div className="border-r"></div> */}

          <h1 className="font-bold text-fuchsia-400">
            {parseInt(iteam?.overall_gain || 0, 10)}%
          </h1>
        </div>
      </div>

      {/*  */}

      <div className="p-2 border border-gray-20 gap-14">
        <div className="flex items-center justify-between p-2">
          <div className="flex gap-2 items-center">
            <h2 className="m-0">{iteam?.username} updated the value to</h2>
            <span>
              <h2 className="m-0">{iteam?.new_number}</h2>
            </span>

            <div className=" flex items-center ">
              <div className="text-orange-400 bg-orange-100 px-1 p-0.5 rounded">
                {parseInt(iteam?.gain || 0, 10)}%
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <RiDeleteBinLine
              className="text-slate-500"
              size={17}
              onClick={handleDeleteUpdate}
            />
          </div>
        </div>
        <div className="flex items-center justify-between p-2">
          <div className="flex items-center gap-5">
            <IoBookOutline className="text-slate-400" />
            <div dangerouslySetInnerHTML={{ __html: iteam?.note }}></div>
          </div>
        </div>

        <div className="flex items-center gap-8 p-2 border border-gray-200">
          <div className="items-center">
            <CiFaceSmile size={15} />
          </div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => setUpdate_key_id(iteam?.update_key_id)}
          >
            <FaRegCommentDots className="text-lg" />
            <span>
              <h3 className="text-base">Comment on update </h3>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateKeyResultCard;
