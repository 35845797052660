import axios from "axios";
import { baseURL } from "../../utils/config";
import Cookies from "js-cookie";

const apiService = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});

const api = {
  async get(url, params = {}) {
    try {
      const token = Cookies.get("access_token");
      const response = await apiService.get(url, {
        params,
        headers: {
          Authorization: `${token}`,
        },
      });
      return response;
    } catch (err) {
      console.error("GET request error: ", err);
      return false;
    }
  },

  async post(url, data) {
    try {
      const token = Cookies.get("access_token");
      const response = await apiService.post(url, data, {
        headers: {
          Authorization: `${token}`,
        },
      });
      return response;
    } catch (err) {
      console.error("POST request error: ", err);
      return err.response || err;
    }
  },
  async postpayload(url, data) {
    try {
      const token = Cookies.get("access_token");
      const response = await apiService.post(url, data, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (err) {
      console.error("POST request error: ", err);
      return err.response || err;
    }
  },

  async put(url, data) {
    try {
      const token = Cookies.get("access_token");
      const response = await apiService.put(url, data, {
        headers: {
          Authorization: `${token}`,
        },
      });
      return response;
    } catch (err) {
      console.error("PUT request error: ", err);
      return false;
    }
  },

  async delete(url, params) {
    try {
      const token = Cookies.get("access_token");
      const response = await apiService.delete(url, {
        params,
        headers: {
          Authorization: `${token}`,
        },
      });
      return response;
    } catch (err) {
      console.error("DELETE request error: ", err);
      return false;
    }
  },
};

export { api, apiService };
