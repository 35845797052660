import { Box, Text, HStack, Avatar } from "@chakra-ui/react";
import { getAvatarColor, getInitials } from "../../utils/helpers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseURL } from "../../utils/config";
import { api } from "../../components/global/api";
import ReactQuill from "react-quill";

const CommentSectionTemplate = ({ data, section }) => {
  const [commentsData, setCommentsData] = useState([]);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state.user);
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  const [newComment, setNewComment] = useState("");

  const handleInputChange = (value) => {
    setNewComment(value);
  };

  const handleSubmitComment = async ({
    sender_id,
    receiver_id,
    schedule_id,
  }) => {
    const payload = {
      sender_id: sender_id,
      text: newComment,
      receiver_id: receiver_id,
      schedule_id: schedule_id,
    };

    try {
      const response = await api.post(baseURL + "/template-comments/", payload);

      if (response.status === 201) {
        setNewComment("");
        commentData();
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleAddComment = ({ sender_id, receiver_id, schedule_id }) => {
    handleSubmitComment({ sender_id, receiver_id, schedule_id });
  };

  const commentData = async () => {
    const response = await api.get(baseURL + "/template-comments/", {
      sender_id: currentEmployee?.user_id,
      receiver_id: data?.key_results[0]?.participant_id,
      schedule_id: data?.schedule_id,
    });
    setCommentsData(response?.data || []);
  };

  useEffect(() => {
    commentData();
  }, []);

  return (
    <div className="bg-[#fff8f8] p-5 rounded">
      <h1 className="font-sans text-2xl font-semibold flex justify-center italic mb-10">
        Comment Section
      </h1>
      <div className="font-sans">
        <ul className="space-y-16 mb-12">
          {commentsData?.comments?.length > 0 &&
            commentsData?.comments?.map((comment, index) => (
              <li key={index} className="flex items-center justify-center">
                {parseInt(comment?.sender_id) ===
                parseInt(currentEmployee?.user_id) ? (
                  <div className="flex items-center justify-end space-x-3 w-full">
                    <ReactQuill
                      value={comment?.text} // The comment text to display
                      readOnly={true} // Set to readOnly
                      theme="bubble" // Optional: use bubble theme for a nicer display
                      className="bg-gray-50 border border-gray-300 rounded-md p-2  overflow-y-auto" // Tailwind CSS classes
                    />
                    <div className="flex justify-center items-center">
                      <div
                        className="w-8 h-8 border text-lg border-black rounded-full flex items-center justify-center text-white  relative"
                        style={{
                          backgroundColor: commentsData?.sender_details
                            ?.profile_image
                            ? "initial"
                            : getAvatarColor(
                                commentsData?.sender_details?.username
                              ),
                        }}
                        size={22}
                      >
                        {commentsData?.sender_details?.profile_image ? (
                          <img
                            src={commentsData?.sender_details?.profile_image}
                            className="w-full h-full object-cover rounded-full"
                            alt="Profile"
                          />
                        ) : (
                          getInitials(commentsData?.sender_details?.username)
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <h1 className="font-semibold text-sm">
                        {commentsData?.sender_details?.username}
                      </h1>
                      <h1 className="text-gray-600 font-semibold text-sm">
                        {commentsData?.sender_details?.emails}
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-start space-x-3 w-full">
                    <div className="flex justify-center items-center">
                      <div
                        className="w-8 h-8 border text-lg border-black rounded-full flex items-center justify-center text-white  relative"
                        style={{
                          backgroundColor: commentsData?.receiver_details
                            ?.profile_image
                            ? "initial"
                            : getAvatarColor(
                                commentsData?.receiver_details?.username
                              ),
                        }}
                        size={22}
                      >
                        {commentsData?.receiver_details?.profile_image ? (
                          <img
                            src={commentsData?.receiver_details?.username}
                            className="w-full h-full object-cover rounded-full"
                            alt="Profile"
                          />
                        ) : (
                          getInitials(commentsData?.receiver_details?.username)
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <h1 className="font-semibold text-sm">
                        {commentsData?.receiver_details?.username}
                      </h1>
                      <h1 className="text-gray-600 font-semibold text-sm">
                        {commentsData?.receiver_details?.email}
                      </h1>
                    </div>
                    <ReactQuill
                      value={comment?.text} // The comment text to display
                      readOnly={true} // Set to readOnly
                      theme="bubble" // Optional: use bubble theme for a nicer display
                      className="bg-gray-50 border border-gray-300 rounded-md p-2  overflow-y-auto" // Tailwind CSS classes
                    />
                  </div>
                )}
              </li>
            ))}
        </ul>

        <div className="mt-8 p-4 border-t border-gray-100 flex justify-center items-center space-x-32">
          <div className="flex flex-col space-y-4 w-full">
            <ReactQuill
              value={newComment}
              onChange={handleInputChange}
              placeholder="Write your comment here..."
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline"],
                  ["link", "image"],
                  ["clean"], // remove formatting button
                ],
              }}
              className="border border-gray-200 rounded-md bg-gray-50 text-sm focus:outline-none"
            />
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <div
                  className="w-8 h-8 border text-lg border-black rounded-full flex items-center justify-center text-white  relative"
                  style={{
                    backgroundColor: currentUser?.profile_image
                      ? "initial"
                      : getAvatarColor(currentUser?.username),
                  }}
                >
                  {currentUser?.profile_image ? (
                    <img
                      src={currentUser?.profile_image}
                      className="w-full h-full object-cover rounded-full"
                      alt="Profile"
                    />
                  ) : (
                    getInitials(currentUser?.username)
                  )}
                </div>
                <div className="flex flex-col">
                  <h1 className="font-semibold text-sm">
                    {currentUser?.username}
                  </h1>
                </div>
              </div>
              <button
                disabled={!newComment}
                onClick={(e) => {
                  e.preventDefault();
                  handleAddComment({
                    sender_id: currentEmployee?.user_id,
                    receiver_id: data?.key_results[0]?.participant_id,
                    schedule_id: data?.schedule_id,
                  });
                }}
                className="bg-blue-500 text-white p-2 px-4 rounded-md hover:bg-blue-600 text-xs"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentSectionTemplate;
