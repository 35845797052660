import React, { useEffect, useState } from "react";
import { baseURL } from "../../utils/config";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import ReportController from "../../components/Report/ReportController";
import DrawerContainer from "../../components/Okrs/Drawer";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import DashboardHeader from "../../components/global/DashboardHeader";
import { api }  from "../../components/global/api";

const ReportDraftPage = () => {
  const navigate = useNavigate();
  const [reportData, setReportData] = useState();
  const [keyResultReports, setkeyResultReports] = useState([]);
  const [goalReports, setGoalReports] = useState([]);
  const [okrReports, setokrReports] = useState([]);
  const [teamReports, setTeamReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const currentEmployee = currentUser?.employees;
  const user = useSelector((state) => state.user);
  const plan = user?.currentPlan;
  const params = useParams();
  const [drawerData, setDrawerData] = useState();
  const okrSlice = useSelector((state) => state?.okr);

  if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
    navigate("/settings?tab=Billing");
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      const currentCompany = currentUser?.employees[user?.currentCompany];
      const res = await api.get(baseURL + "/stats/", {
        report_id: params?.id,
      });
      setReportData(res.data);
      setkeyResultReports(res.data?.key_results);
      setokrReports(res.data?.okr);
      setGoalReports(res.data?.goals);
      setTeamReports(res.data?.teams);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [params?.id]);

  const getOkrData = async (okr_id) => {
    try {
      const res = await api.get(baseURL + "/okr/", {
        okr_id: okr_id,
        company_id: currentEmployee[user?.currentCompany]?.company_id,
      });
      setDrawerData(res.data?.children[0]);
      // setGoalOkrData(res.data)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (okrSlice?.openOkrDrawer) {
      getOkrData(okrSlice?.openOkrDrawer);
    }
  }, [okrSlice?.openOkrDrawer]);

  return (
    <div className="relative h-screen p-4">
      <div
        className="flex flex-col p-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        <div className="p-4 h-full bg-white rounded-lg">
          <CustomScrollbar>
            <ReportController
              keyResultReports={keyResultReports}
              okrReports={okrReports}
              goalReports={goalReports}
              teamReports={teamReports}
              loading={loading}
            />
          </CustomScrollbar>
        </div>
        <DrawerContainer getOkrs={() => {}} drawerData={drawerData} />
      </div>
    </div>
  );
};

export default ReportDraftPage;
